import Container from '@components/Container'
import SEO from '@components/SEO'
import { css } from '@emotion/react'
import { FreeVideosQuery } from '@graphql-types'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import moment from 'moment'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FaPlayCircle, FaYoutube } from 'react-icons/fa'
import { maxWidth, responsiveColumn } from 'src/themes'

const FreeVideos = ({ data }: PageProps<FreeVideosQuery>) => {
	const { t } = useTranslation()
	return (
		<Container>
			<SEO title={t('layout:videos')} keywords={[]} />
			<h1>{t('free:videos.title')}</h1>
			<h2>{t('free:videos.5min_programming.subtitle')}</h2>
			<div
				css={css`
					display: flex;
					margin: 1rem;
					gap: 1rem;

					${responsiveColumn};

					> * {
						flex: 1;
					}
				`}>
				<p>
					<Trans i18nKey='free:videos.5min_programming.description'></Trans>
				</p>
				<GatsbyImage
					image={
						data.programmingPreview?.childImageSharp
							?.gatsbyImageData
					}
					alt={t('free:videos.title')}
				/>
			</div>
			<h3>{t('free:videos.latest_videos')}</h3>
			<div
				css={css`
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;
					margin: 20px auto;
					font-weight: bold;

					a {
						display: flex;
						justify-content: center;
						align-items: center;
						background: #e80310;
						border-radius: 5px;
						color: white;
						padding: 5px 10px;

						svg {
							margin-right: 5px;
						}
					}
				`}>
				<a
					target='_blank'
					rel='noopener'
					href='https://www.youtube.com/c/TeckyAcademy?sub_confirmation=1'>
					<FaYoutube size={28} /> {t('Subscribe now')}
				</a>
			</div>
			<div
				css={css`
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
				`}>
				{data.allYoutubeVideo.edges
					.filter((row) => {
						return row.node.title?.match(/一個字/)
					})
					.map((row) => (
						<div
							css={css`
								width: 300px;
								margin: 10px;

								${maxWidth[2]} {
									width: 45%;
								}

								${maxWidth[1]} {
									width: 90%;
								}

								> a > div {
									position: relative;
									border: #ffffff solid 2px;

									> div:last-child {
										position: absolute;
										top: 0;
										left: 0;
										right: 0;
										bottom: 0;
										display: flex;
										justify-content: center;
										align-items: center;
										transition: all 0.5s;
										background: rgba(255, 255, 255, 0.3);
										opacity: 0;
									}
									&:hover > div {
										opacity: 1;
									}
								}

								h4 {
									font-weight: bold;
									margin: 10px 0 5px;
								}
							`}>
							<a
								href={
									`https://www.youtube.com/watch?v=` +
									row.node.videoId
								}
								target='_blank'
								rel='noopener'>
								<div>
									<div>
										<GatsbyImage
											image={
												row.node?.localThumbnail
													?.childrenImageSharp?.[0]
													?.gatsbyImageData
											}
											alt={row.node.title!}
										/>
									</div>
									<div>
										<FaPlayCircle
											size={40}
											color='#0A3577'
										/>
									</div>
								</div>
								<h4>
									{row.node.title?.replace(
										/【#?一個字.?Programming】/g,
										''
									)}
								</h4>
								<h5>
									{t('free:videos.published_at')}:{' '}
									{moment(row.node.publishedAt).format(
										'YYYY-MM-DD'
									)}
								</h5>
							</a>
						</div>
					))}
			</div>
		</Container>
	)
}

export default FreeVideos

export const query = graphql`
	query FreeVideos($language: String!) {
		...TranslationFragment
		allYoutubeVideo(sort: { fields: publishedAt, order: DESC }) {
			edges {
				node {
					title
					description
					videoId
					thumbnail {
						url
					}
					localThumbnail {
						childrenImageSharp {
							gatsbyImageData(width: 300, placeholder: BLURRED)
						}
					}
					originalID
					publishedAt
					privacyStatus
				}
			}
		}
		programmingPreview: file(
			relativePath: { eq: "5-min-programming.png" }
		) {
			childImageSharp {
				gatsbyImageData(width: 700, placeholder: BLURRED)
			}
		}
	}
`
